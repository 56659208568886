import { DiretorioGuard } from './guard/diretorio.guard';
import { GuestGuard } from './guard/guest-guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './guard/auth-guard';
import { OnlineGuard } from './guard/online.guard';

const routes: Routes = [
  { path: 'auth',
    loadChildren: () => import('./autenticacao/autenticacao.module').then(m => m.AutenticacaoModule),
  },
  { path: 'relatorio',
    loadChildren: () => import('./relatorio/relatorio.module').then(m => m.RelatorioModule),
    canActivate: [AuthGuard]
  },
  { path: 'chatbot',
    loadChildren: () => import('./chatbot/chatbot.module').then(m => m.ChatbotModule),
    canActivate: [AuthGuard]
  },
  { path: 'online',
    loadChildren: () => import('./online/online.module').then(m => m.OnlineModule),
    canActivate: [AuthGuard, OnlineGuard]
  },
  { path: 'diretorios',
    loadChildren: () => import('./diretorios/diretorios.module').then(m => m.DiretoriosModule),
    canActivate: [AuthGuard, DiretorioGuard]
  },
  { path: 'conta',
    loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilModule),
    canActivate: [AuthGuard]
  },
  { path: 'ajuda',
    loadChildren: () => import('./ajuda/ajuda.module').then(m => m.AjudaModule),
    canActivate: [AuthGuard]
  },
  { path: '', redirectTo: 'relatorio', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
